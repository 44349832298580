<template>
  <v-dialog v-model="dialog" fullscreen>
    <template v-slot:activator="{on}">
      <v-btn class="my-2" v-on="on">Theme Editor</v-btn>
    </template>
    <v-card>
      <v-card-title class="black white--text">
        <span>Theme Editor: Modena</span>
        <v-spacer />
        <v-btn-toggle v-model="mode" dark mandatory>
          <v-btn small>Dark</v-btn>
          <v-btn small>Light</v-btn>
        </v-btn-toggle>
      </v-card-title>
      <v-divider />
      <v-card-text class="pt-2 mt-0 grey lighten-3">
        <v-form v-model="valid">
          <div
            v-for="(input, i) in inputs"
            :key="`theme-input-${i}`"
          >
            <component
              :is="input.component"
              v-model="internalValue[modeString][input.key]"
              class="mt-5"
              v-bind="input.props"
            />
            <v-divider />
          </div>
        </v-form>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn color="success" @click="saveAndClose()">Save & Close</v-btn>
        <v-spacer />
        <v-btn color="warning" @click="reset()">Reset</v-btn>
        <v-btn color="warning" @click="dialog = false">Dismiss</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'ThemeInput',
    props: {
      value: Object,
    },
    data: vm => ({
      valid: false,
      dialog: false,
      mode: 0,
      internalValue: vm.value ?? {},
    }),
    computed: {
      modeString () {
        return this.mode === 1 ? 'light' : 'dark'
      },
      menuTableHeaders () {
        return [
          {
            title: 'ID',
            key: 'id',
            component: 'VTextField',
            getProps (item) {
              return {
                label: 'ID',
                value: item.id,
                dense: true,
                solo: true,
                hideDetails: true,
              }
            },
          },
          {
            title: 'Title',
            key: 'title',
            component: 'VTextField',
            getProps (item) {
              return {
                label: 'Title',
                value: item.title,
                dense: true,
                solo: true,
                hideDetails: true,
              }
            },
          },
          {
            title: 'To',
            key: 'to',
            component: 'VTextField',
            getProps (item) {
              return {
                label: 'To',
                value: item.to,
                dense: true,
                solo: true,
                hideDetails: true,
              }
            },
          },
          {
            title: 'Order',
            key: 'order',
            component: 'VTextField',
            getProps (item) {
              return {
                label: 'Order',
                value: item.order,
                dense: true,
                solo: true,
                hideDetails: true,
              }
            },
          },
          {
            title: 'Active Class',
            key: 'activeClass',
            component: 'VTextField',
            getProps (item) {
              return {
                label: 'Active Class',
                value: item.activeClass,
                dense: true,
                solo: true,
                hideDetails: true,
              }
            },
          },
        ]
      },
      inputs () {
        return [
          {
            key: 'website',
            component: 'VJsonEditor',
            props: {
              label: 'Override website default theme',
              value: this.internalValue?.[this.modeString]?.website,
            },
          },
          {
            key: 'websiteTopBar',
            component: 'VSchemaBuilder',
            props: {
              value: this.internalValue?.[this.modeString]?.websiteTopBar,
            },
          },
          {
            key: 'appbarMenuLinks',
            component: () => import('./InlineEditableTable.vue'),
            props: {
              label: 'Appbar Menu Links',
              headers: this.menuTableHeaders,
              value: this.internalValue?.[this.modeString]?.appbarMenuLinks,
            },
          },
          {
            key: 'navigationMenuLinks',
            component: () => import('./InlineEditableTable.vue'),
            props: {
              label: 'Navigation Menu Links',
              headers: this.menuTableHeaders,
              value: this.internalValue?.[this.modeString]?.navigationMenuLinks,
            },
          },
          {
            key: 'footerMenuLinks',
            component: () => import('./InlineEditableTable.vue'),
            props: {
              label: 'Footer Menu Links',
              headers: this.menuTableHeaders,
              value: this.internalValue?.[this.modeString]?.footerMenuLinks,
            },
          },
          {
            key: 'footerExtraLinks',
            component: () => import('./InlineEditableTable.vue'),
            props: {
              label: 'Footer Extra Column Links',
              headers: this.menuTableHeaders,
              value: this.internalValue?.[this.modeString]?.footerExtraLinks,
            },
          },
        ]
      },
    },
    watch: {
      value () {
        this.internalValue = this.value ?? {}
        this.normalizeValue()
      },
    },
    created () {
      this.normalizeValue()
    },
    methods: {
      normalizeValue () {
        if (!this.internalValue.light || Array.isArray(this.internalValue.light)) {
          this.internalValue.light = {
            ...(this.internalValue?.light ?? {}),
          }
        }
        if (!this.internalValue.dark || Array.isArray(this.internalValue.dark)) {
          this.internalValue.dark = {
            ...(this.internalValue?.dark ?? {}),
          }
        }
      },
      saveAndClose () {
        this.normalizeValue()
        this.$emit('input', this.internalValue)
        this.dialog = false
      },
      reset () {
        this.internalValue = this.value ?? {}
        this.normalizeValue()
      },
    },
  }
</script>
